<template>
  <div class="flex justify-end items-center">
    <asom-button
      outline
      size="sm"
      text="Add Cash Box"
      variant="primary"
      @click="toggleModal(true)"
    />
    <asom-modal
      v-model="showModal"
      title="Add Outgoing Cash Box"
      size="md"
      :dismissible="false"
    >
      <asom-alert v-if="error" variant="error" :errorMessage="error" />
      <div>
        <asom-form-field
          label="Cash Box Serial Number"
          required
          :state="inputStates('outgoingCashBox.cashBoxSerialNumber')"
        >
          <scan-code
            v-model="outgoingCashBox.cashBoxSerialNumber"
            :state="inputStates('outgoingCashBox.cashBoxSerialNumber')"
          />
        </asom-form-field>
        <asom-form-field
          label="Cash Box Status"
          required
          :state="inputStates('outgoingCashBox.status')"
        >
          <asom-input-radio-group
            horizontal
            v-model="outgoingCashBox.status"
            :options="cashBoxStatusOptions"
            label="Is Faulty?"
          />
        </asom-form-field>

<!--        <asom-form-field-->
<!--          v-if="outgoingCashBox.status === '1'"-->
<!--          label="Certis Bag Serial Number"-->
<!--          required-->
<!--          :state="inputStates('outgoingCashBox.certisBagSerialNumber')"-->
<!--        >-->
<!--          <scan-code-->
<!--            v-model="outgoingCashBox.certisBagSerialNumber"-->
<!--            :state="inputStates('outgoingCashBox.certisBagSerialNumber')"-->
<!--          />-->
<!--        </asom-form-field>-->

        <asom-form-field
          label="Remarks(e.g Faulty, Scheduled Servicing)"
          required
          :state="inputStates('outgoingCashBox.remarks')"
        >
          <asom-input-textarea
            v-model="outgoingCashBox.remarks"
            :state="inputStates('outgoingCashBox.remarks')"
          />
        </asom-form-field>
        <div class="flex flex-row-reverse pt-4 gap-4">
          <asom-button
          @click="onSubmit"
          text="Add"
          :disabled="isLoading"
          :loading="isLoading"
          />
          <asom-button
            @click="toggleModal(false)"
            text="Cancel"
            variant="secondary"
          />
        </div>
      </div>
    </asom-modal>
  </div>
</template>
<script>
import { inject} from 'vue';
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import inputStates from "@/mixins/inputStates";
import ScanCode from "../../../_ScanCode.vue";
import AsomInputRadioGroup from '../../../../../components/input/radio/AsomInputRadioGroup.vue';
import { mapGetters } from "vuex";
import { getCashBoxDetails } from "../../../../../services/cashManagement.service"

export default {
  components: {
    ScanCode,
    AsomInputRadioGroup,
  },
  mixins: [inputStates],
  setup() {
    const stationCashCollectionForm = inject('stationCashCollectionForm');
    const setOutgoingCashBoxList = inject('setOutgoingCashBoxList');

    return {
      v$: useVuelidate(),
      stationCashCollectionForm,
      setOutgoingCashBoxList,
    };
  },
  data() {
    return {
      outgoingCashBox: {
        cashBoxSerialNumber: "",
        certisBagSerialNumber: "",
        status: '1',
        remarks: "",
      },
      error: null,
      showModal: false,
      isLoading: false,
    };
  },
  validations() {
    const validations = {
      outgoingCashBox: {
        cashBoxSerialNumber: {
          required,
        },
        status: {
          required,
        },
        remarks: {
          required,
        },
      },
    };
    switch (this.outgoingCashBox.status)
    {
      case '1':
        validations.outgoingCashBox.certisBagSerialNumber = {
          //required,
        };
        break;
      default:
        validations.outgoingCashBox.remarks = {
          required,
        };
        break;
    }

    return validations;
  },
  computed: {
    ...mapGetters({
      stationId: "selectedStation/id",
      isOnline: "apiStatus/isCMModuleOnline",
    }),
    cashBoxStatusOptions() {
      return [
        { value: '1', label: "Full" },
        { value: '2', label: "Empty" },
        // { value: '3', label: "Faulty" },
      ]
    },
    isCashBoxNoAlreadyInList() {
      return this.stationCashCollectionForm.outgoing_cashBoxList
          .some(item => item.cashBoxSerialNumber === this.outgoingCashBox.cashBoxSerialNumber);
    },
  },
  methods: {
    toggleModal(value) {
      this.showModal = value;
      if (!value) {
        this.outgoingCashBox.cashBoxSerialNumber = "";
        this.outgoingCashBox.certisBagSerialNumber = "";
        this.outgoingCashBox.status = '1';
        this.outgoingCashBox.remarks = "";
        this.error = null;
        this.v$.$reset();
      }
      this.isLoading = false;
    },
    async onSubmit() {
      this.error = null;
      this.v$.$touch();
      if (this.v$.outgoingCashBox.$invalid) {
        this.error = "Please complete all required fields";
      } else if (this.isCashBoxNoAlreadyInList) {
        this.error = `Cash Box ${this.outgoingCashBox.cashBoxSerialNumber} has been added`;
      } else if (this.isOnline) {
        await this.verifyCashBoxOnline();
      } else {
        const newList = [
          ...this.stationCashCollectionForm.outgoing_cashBoxList,
          {
            cashBoxSerialNumber: this.outgoingCashBox.cashBoxSerialNumber.trim(),
            certisBagSerialNumber: this.outgoingCashBox.certisBagSerialNumber.trim(),
            isFaulty: this.outgoingCashBox.status === '3',
            isEmpty: this.outgoingCashBox.status === '2',
            remarks: this.outgoingCashBox.remarks,
            removalDate: null,
            gtmNumber: null,
          },
        ]
        this.setOutgoingCashBoxList(newList);
      }


      if (this.error) {
        this.$scrollTop();
      } else {
        this.toggleModal(false);
      }
    },
    async verifyCashBoxOnline() {
      this.isLoading = true;
      const result = await getCashBoxDetails({
        cashBoxSerialNo: this.outgoingCashBox.cashBoxSerialNumber,
        stationId: this.stationId})
      this.isLoading = false;
      if (!result.success) {
        this.error = result.payload; //`Cash Box ${this.outgoingCashBox.cashBoxSerialNumber} not found`;
        return;
      } else {
        const newList = [
          ...this.stationCashCollectionForm.outgoing_cashBoxList,
          {
            cashBoxSerialNumber: this.outgoingCashBox.cashBoxSerialNumber.trim(),
            certisBagSerialNumber: this.outgoingCashBox.certisBagSerialNumber.trim(),
            isFaulty: this.outgoingCashBox.status === '3',
            isEmpty: this.outgoingCashBox.status === '2',
            remarks: this.outgoingCashBox.remarks,
            removalDate: result.payload.data.removalDate,
            gtmNumber: result.payload.data.gtmNumber,
          },
        ]
        this.setOutgoingCashBoxList(newList);
      }
    }
  },
};
</script>
