<template>
  <div class="space-y-4">
    <h2 class="text-xl font-semibold">Confirmation</h2>
    <asom-alert v-if="error" variant="error" :error-message="error" />
    <!-- <asom-alert v-if="!hasIncomingItems && !hasOutgoingItems" 
      variant="error" 
      title="You don't have any item added to this station cash collection form.">
    </asom-alert> -->
    <asom-card title="Incoming Cash Order Summary">
      <article v-if="hasIncomingItems" class="space-y-1">
        <asom-form-field label="Duffle Bag Serial Number">
          <div class="flex items-center gap-2">
            {{formData.duffleBagSerialNumber}}
            <asom-badge v-if="formData.isDuffleBagRejected" variant="error">Rejected</asom-badge>
          </div>
        </asom-form-field>
        <asom-form-field v-if="formData.isDuffleBagRejected" label="Rejection Remarks">
          {{formData.duffleBagRejectionRemarks}}
        </asom-form-field>
        <asom-form-field v-for="cashOrderId in formData.cashOrderIdList"
          :key="cashOrderId"
          label=" "
          class="pt-4"
        >
          <template v-slot:label>
            <h3 class="font-semibold text-lg">{{cashOrderTitle(cashOrderId)}}</h3>
          </template>
          <div v-if="formData.isDuffleBagRejected" class="text-sm">
            No item
          </div>
          <div class="space-y-4"  v-else-if="cashOrderTypeEnum(cashOrderId) === 1">
            <h4 class="font-semibold">
              Incoming Cash Bag List
            </h4>
            <div v-if="incomingCashBagList(cashOrderId).length == 0" class="text-sm">No cash bag added</div>
            <asom-client-table
              v-else
              :filterable="false"
              :pagination="false"
              :columns="[
                'no',
                'cashBagSerialNumber',
                'status',
                'remarks',
              ]"
              :data="incomingCashBagList(cashOrderId)"
            >
              <template v-slot:header_cashBagSerialNumber>Cash Bag Serial Number</template>
              <template v-slot:no="slotScoped">
                {{slotScoped.rowIndex + 1}}
              </template>
              <template v-slot:status="slotScoped">
                {{slotScoped.rowData.isRejected ? 'Rejected' : 'Accepted'}}
              </template>
            </asom-client-table>
          </div>
          <div class=""  v-else-if="cashOrderTypeEnum(cashOrderId) === 2">
            <h4 class="font-semibold">
              Incoming Coin Tubes
            </h4>
            <denominations
              :label="'Coin Tubes Received'"
              readonly
              show-remarks
              :noOf10CentTubes="receivedCoinTube(cashOrderId).noOf10CentTubes || 0"
              :noOf20CentTubes="receivedCoinTube(cashOrderId).noOf20CentTubes || 0"
              :noOf50CentTubes="receivedCoinTube(cashOrderId).noOf50CentTubes || 0"
              :noOf1DollarTubes="receivedCoinTube(cashOrderId).noOf1DollarTubes || 0"
              :remarks="receivedCoinTube(cashOrderId).remarks"
            />
            <denominations
              :label="'Coin Tubes Rejected'"
              v-if="rejectedCoinTube(cashOrderId).hasRejectedCoinTube"
              readonly
              show-remarks
              :noOf10CentTubes="rejectedCoinTube(cashOrderId).noOf10CentTubes || 0"
              :noOf20CentTubes="rejectedCoinTube(cashOrderId).noOf20CentTubes || 0"
              :noOf50CentTubes="rejectedCoinTube(cashOrderId).noOf50CentTubes || 0"
              :noOf1DollarTubes="rejectedCoinTube(cashOrderId).noOf1DollarTubes || 0"
              :remarks="rejectedCoinTube(cashOrderId).remarks"
            />
          </div>
        </asom-form-field>
      </article>
      <p v-else class="font-medium text-sm">
        Skipped selecting cash order
      </p>
    </asom-card>
    <asom-card title="Outgoing Item Summary">
      <div class="space-y-4" v-if="hasOutgoingItems">
        <h3 class="font-semibold">SCCF (TE Bag) List</h3>
        <asom-client-table
          v-if="formData.outgoing_cashBagList.length > 0"
          :filterable="false"
          :pagination="false"
          :columns="[
            'no',
            'cashBagSerialNumber',
            'remarks',
          ]"
          :data="formData.outgoing_cashBagList"
        >
          <template v-slot:header_cashBagSerialNumber>TE Bag Serial Number</template>
          <template v-slot:no="slotScoped">
            {{slotScoped.rowIndex + 1}}
          </template>
        </asom-client-table>
        <p v-else class="pt-2">Nil bag returned</p>
        <template v-if="formData.outgoing_cashBoxList.length > 0">
          <h3 class="font-semibold">SCCF (Cash Box) List</h3>
          <asom-client-table
            :filterable="false"
            :pagination="false"
            :columns="[
              'no',
              'cashBoxSerialNumber',
              'isFaulty',
              'remarks',
            ]"
            :data="formData.outgoing_cashBoxList"
          >
            <template v-slot:no="slotScoped">
              {{slotScoped.rowIndex + 1}}
            </template>
            <template v-slot:isFaulty="slotScoped">
              {{slotScoped.data ? 'Yes' : 'No'}}
            </template>
          </asom-client-table>
        </template>
        <template v-if="formData.outgoing_nonCashList.length > 0">
          <h3 class="font-semibold">SCCF (Non Cash Item) List</h3>
          <asom-client-table
            :filterable="false"
            :pagination="false"
            :columns="[
              'no',
              'description',
              'serialNumber',
              'remarks',
            ]"
            :data="formData.outgoing_nonCashList"
          >
            <template v-slot:header_serialNumber>Serial Number</template>
            <template v-slot:no="slotScoped">
              {{slotScoped.rowIndex + 1}}
            </template>
          </asom-client-table>
          
          <h3 class="font-semibold">Supporting Document</h3>
          <asom-upload-file-list :files="formData.documentList" disabled-remove />
        </template>
      </div>
      <p v-else class="font-medium text-sm">
        No outgoing item
      </p>
    </asom-card>
    
    <asom-card title="Supporting Documents" >
      <div class="space-y-4">
        <asom-form-field
          description="Upload supporting documents, e.g. cash delivery form here"
        >
          <asom-upload 
            v-if="!isOfflineData"
            :isOnline="isOnline"
            v-model="formData.attachments"
            :category="attachmentCategories.CASH_MANAGEMENT"
             @update:modelValue="setOffLineAttachments"
          >
          </asom-upload>
          <asom-upload-file-list
          v-else
            class="AsomUpload__FileList"
            :files="formData.attachments"
          
          />
        </asom-form-field>
      </div>
    </asom-card>
    
    <!-- <asom-alert v-else variant="warning"
      title="Uploading files not supported in offline mode"
    ></asom-alert> -->
    <asom-card title="Signatures">
      <asom-grid :sm="1" :md="2">
        <div>
          <asom-form-field label="Staff (SMRT)">
            <asom-input-text v-if="!$route.query.id"
              :modelValue="userName"
              disabled
            />
            <asom-input-text v-else
            :modelValue="formData.officerName"
              disabled
            />
          </asom-form-field>
          <asom-form-field label="Signature (SMRT)"
            :state="inputStates('formData.officerSignatureData')"
            error="Signature is required">
            <!-- -->
            <asom-signature-pad
              v-if="!isOfflineData"
              :width="300"
              :modelValue="formData.officerSignatureData"
              @update:modelValue="setOfficerSignatureData"
            />
             <!-- review signature-->
            <asom-signature-view
              v-else
              :offline-image-src="formData.officerSignatureData"
            />
          </asom-form-field>
        </div>
        <div>
          <asom-form-field
            label="Contractor Name"
            :state="inputStates('formData.contractorName')"
          >
            <asom-input-text
              :state="inputStates('formData.contractorName')"
              :modelValue="formData.contractorName"
              @update:modelValue="setContractorName"
              :disabled="(isOfflineData && formData.contractorName)"
            />
          </asom-form-field>
          <asom-form-field
            label="Signature (Contractor)"
            :state="inputStates('formData.contractorSignatureData')"
            error="Signature is required">
            <asom-signature-pad
              v-if="!isOfflineData"
              :width="300"
              :modelValue="formData.contractorSignatureData"
              @update:modelValue="setContractorSignatureData"
            />
            <asom-signature-view
              v-else
              :offline-image-src="formData.contractorSignatureData"
            />
          </asom-form-field>
        </div>
      </asom-grid>
    </asom-card>
    
    <article class="flex justify-between pt-4">
      <asom-button outline
        v-if="!isOfflineData"
        text="Back"
        variant="secondary"
        @click="onBackClicked"
      />
      <div v-else></div>
      <div>
        <asom-button
          text="Save as Draft"
          variant="primary"
          outline
          @click="onSubmitBtnClicked(true)"
        />
        <asom-button
          class="ml-5"
          text="Confirm Transaction"
          variant="primary"
          @click="onSubmitBtnClicked(false)"
        />
      </div>
    </article>
   
    <asom-modal
      title="Confirm"
      v-model="showModal"
      :dismissible="false"
    >
      <p class="pt-4">Are you sure you would like to proceed?</p>

      <div class="flex flex-row-reverse pt-4">
        <div class="pl-4">
          <asom-button
          @click="onSubmit"
          :disabled="isSubmitting"
          :loading="isSubmitting"
          text="OK"
          />
        </div>
        <div>
          <asom-button
          @click="onCloseModal"
          text="Cancel"
          variant="secondary"
          />
        </div>
      </div>
    </asom-modal>
  </div>
</template>
<script>
import { inject, ref } from 'vue';
import { mapGetters } from 'vuex';
import { useVuelidate } from "@vuelidate/core";
// import { required } from "@vuelidate/validators";
import inputStates from "@/mixins/inputStates";
import get from "lodash.get"
import Denominations from "../../../_Denominations.vue"
import AsomUploadFileList from '@/components/input/upload/_AsomUploadFileList'
import { imageDataToBlob } from '../../../../../helpers/imageDataToBlob';
import { uploadFile } from '../../../../../services/file.service';
import { createSaveAsDraftSccf } from '../../../../../services/cashManagement.service';
import AsomFormField from '../../../../../components/form_display/AsomFormField.vue';
import { ATTACHMENT_CATEGORIES } from "@/constants/APIEnums/attachmentEnums";

const hasCoin = ({
  noOf10CentTubes = 0,
  noOf20CentTubes = 0,
  noOf50CentTubes = 0,
  noOf1DollarTubes = 0,
}) => noOf10CentTubes + noOf20CentTubes + noOf50CentTubes + noOf1DollarTubes > 0;

export default {
  components: {
    Denominations,
    AsomUploadFileList,
    AsomFormField
},
  mixins: [inputStates],
  setup() {
    const showModal = ref(false);
    const setShowModal = (newValue) => {
      showModal.value = newValue;
    }

    const isOfflineData = inject('isOfflineData');
    const isOfflineDataEditable = inject('isOfflineDataEditable');
    const currentStep = inject('currentStep');
    const setCurrentStep = inject('setCurrentStep');
  
    const error = ref('');
    const setError = (newValue) => {
      error.value = newValue;
    }

    const isSubmitting = ref(false);
    const setIsSubmitting = (newValue) => {
      isSubmitting.value = newValue;
    }

    const formData = inject('stationCashCollectionForm');
    const setOfficerSignatureData = inject('setOfficerSignatureData');
    const setOfficerSignatureId = inject('setOfficerSignatureId');
    const setContractorName = inject('setContractorName');
    const setContractorSignatureData = inject('setContractorSignatureData');
    const setContractorSignatureId = inject('setContractorSignatureId');
    const setOffLineAttachments = inject('setOffLineAttachments');
    return {
      v$: useVuelidate(),
      currentStep,
      setCurrentStep,
      showModal,
      setShowModal,
      error,
      setError,
      isSubmitting,
      setIsSubmitting,
      formData,
      setOfficerSignatureData,
      setOfficerSignatureId,
      setContractorName,
      setContractorSignatureData,
      setContractorSignatureId,
      isOfflineData,
      isOfflineDataEditable,
      setOffLineAttachments
    }
  },
  validations() {
    return {
      formData: {
        // officerSignatureData: { required },
        // contractorName: { required },
        // contractorSignatureData: { required },
      }
    }
  },
  computed: {
    ...mapGetters({
      afcId: "cashManagement/stationCashAccounts/afcId",
      cashOrderOptions: 'cashManagement/cashOrders/getListFromContractorAsOptions',
      cashOrderList: 'cashManagement/cashOrders/getList',
      userName: 'auth/userName',
      coinTubeConfiguration: "cashManagement/configuration/coinTubeConfiguration",
      rosterDws: 'currentRoster/currentRosterShift',
      rosterPlanDate: 'currentRoster/currentRosterPlanDate',
      isOnline: "apiStatus/isCMModuleOnline",
    }),
    hasIncomingItems() {
      return !this.formData.skipCashOrder;
    },
    hasOutgoingItems() {
      return this.formData.outgoing_cashBagList.length > 0 || 
        this.formData.outgoing_cashBoxList.length > 0 ||
        this.formData.outgoing_nonCashList.length > 0
    },
    formDataForSubmission() {
      let fileList =this.formData.attachments
      const cashOrders = this.formData.cashOrderIdList.map(cashOrderId => {
          let cashOrder = {
            cashOrderId,
            cashBags: this.incomingCashBagList(cashOrderId),
          }
          const receivedCoinTube = this.receivedCoinTube(cashOrderId);
          const rejectedCoinTube = this.rejectedCoinTube(cashOrderId);
          if (hasCoin(receivedCoinTube))
            cashOrder.receivedCoinTube = receivedCoinTube;
          if (rejectedCoinTube.hasRejectedCoinTube && hasCoin(rejectedCoinTube))
            cashOrder.rejectedCoinTube = rejectedCoinTube;
          return cashOrder;
        });
      return {
        afcId: this.afcId,
        skipIncoming: this.formData.skipCashOrder,
        skipOutgoing: !this.hasOutgoingItems,
        incoming: {
          duffleBagSerialNumber: this.formData.duffleBagSerialNumber ?? '',
          isDuffleBagRejected: this.formData.isDuffleBagRejected ?? '',
          duffleBagRejectionRemarks: this.formData.duffleBagRejectionRemarks ?? '',
          cashOrders: this.formData.cashOrderIdList.length ? cashOrders : [],
        },
        outgoing: {
          cashBags: this.formData.outgoing_cashBagList,
          cashBoxes: this.formData.outgoing_cashBoxList,
          nonCashItems: this.formData.outgoing_nonCashList,
          nonCashItemAttachmentIds: this.formData.documentList.map(file => file.fileId),
        },
        SMRTSignatureId: this.formData.officerSignatureId || '00000000-0000-0000-0000-000000000000',
        officerName: this.formData.officerName,
        contractorName: this.formData.contractorName || null,
        contractorSignatureId: this.formData.contractorSignatureId || '00000000-0000-0000-0000-000000000000',
        rosterDws: this.rosterDws,
        rosterPlanDate: this.rosterPlanDate,
        attachments: this.formData.attachments.length > 0 && this.formData.attachments[0].originalName ? fileList: this.formData.attachments.map(f => f.fileId) ,
        fileList:fileList
      }
    },
    formDataForOfflineMode() {
      return {
        ...this.formDataForSubmission,
        officerSignature: this.formData.officerSignatureData,
        contractorSignature: this.formData.contractorSignatureData,
     
      }
    },
    attachmentCategories() {
      return ATTACHMENT_CATEGORIES;
    },
  },
  methods: {
    cashOrderTitle(cashOrderId) {
      const details = this.cashOrderList.find(item => item.cashOrderId == cashOrderId);
      return 'Cash Order ' + get(details, 'referenceNo', '');
    },
    cashOrderTypeEnum(cashOrderId) {
      const details = this.cashOrderList.find(item => item.cashOrderId == cashOrderId);
      return get(details, 'cashOrderTypeEnum');
    },
    incomingCashBagList(cashOrderId) {
      return this.formData.incoming_cashBagList.filter(item => item.cashOrderId == cashOrderId);
    },
    receivedCoinTube(cashOrderId) {
      const result = get(this.formData.incoming_receivedCoinTubeList, cashOrderId, {
        noOf10CentTubes: 0,
        noOf20CentTubes: 0,
        noOf50CentTubes: 0,
        noOf1DollarTubes: 0,
        remarks: '',
      })
      return {
        ...result,
        noOf10CentsPerTube: this.coinTubeConfiguration.numberOf10CentsPerTube,
        noOf20CentsPerTube: this.coinTubeConfiguration.numberOf20CentsPerTube,
        noOf50CentsPerTube: this.coinTubeConfiguration.numberOf50CentsPerTube,
        noOf1DollarsPerTube: this.coinTubeConfiguration.numberOf1DollarsPerTube,
      }
    },
    rejectedCoinTube(cashOrderId) {
      const result = get(this.formData.incoming_rejectedCoinTubeList, cashOrderId, {
        noOf10CentTubes: 0,
        noOf20CentTubes: 0,
        noOf50CentTubes: 0,
        noOf1DollarTubes: 0,
        remarks: '',
      });
      return {
        ...result,
        noOf10CentsPerTube: this.coinTubeConfiguration.numberOf10CentsPerTube,
        noOf20CentsPerTube: this.coinTubeConfiguration.numberOf20CentsPerTube,
        noOf50CentsPerTube: this.coinTubeConfiguration.numberOf50CentsPerTube,
        noOf1DollarsPerTube: this.coinTubeConfiguration.numberOf1DollarsPerTube,
        isAccepted: false,
      }
    },
    onBackClicked() {
      this.setCurrentStep(2)
    },
    onCloseModal() {
      this.setShowModal(false);
    },
    onSubmitBtnClicked(status) {
      this.v$.$touch();
      if (this.v$.$invalid) {
        this.setError('Please complete all required fields');
        this.$scrollTop();
        return;
      }
      this.subStatus = status;
      this.setShowModal(true);
    },
    onSubmit() {
      if (this.isOnline) {
        this.createSCCFAsync();
      } else {
        if (this.isOfflineData) {
          const offlineId = get(this.$route.query, 'offlineId');
          this.$store.commit('offlineData/updatePendingSyncData', {
            id: offlineId,
            data: this.formDataForOfflineMode,
          });
        } else {
          this.$store.dispatch('offlineData/sccf', this.formDataForOfflineMode);
        }
        this.setShowModal(false);
        this.$router.push({ name: 'SCCF Main' });
      }
    },
    async createSCCFAsync() {
      this.setError(null);
      this.setIsSubmitting(true);
      const isSignatureUploaded = this.formData.officerSignatureId !== null && this.formData.contractorSignatureId !== null;
      let attachObj = {
          attachments:[]
        }
      if (!isSignatureUploaded && this.formData.officerSignatureData && this.formData.contractorSignatureData) {
        const signatureBlob1 = imageDataToBlob(this.formData.officerSignatureData, 'officer_signature');
        const signatureBlob2 = imageDataToBlob(this.formData.contractorSignatureData, 'contractor_signature');
        let fileLists = [];
        
        if(this.formData.attachments.length > 0  && this.formData.attachments[0].originalName ){
          this.formData.attachments.forEach(el=>{
            fileLists.push(imageDataToBlob(el.base64data, el.originalName))
          })
        } 
        const uploadResult = await uploadFile([ signatureBlob1, signatureBlob2,...fileLists], ATTACHMENT_CATEGORIES.CASH_MANAGEMENT);
        if (!uploadResult.success) {
          this.setError(uploadResult.payload);
          this.setShowModal(false);
          this.setIsSubmitting(false);
          this.$scrollTop()
          return;
        }
        this.setOfficerSignatureId(get(uploadResult, 'payload.files.0.fileId'));
        
        this.setContractorSignatureId(get(uploadResult, 'payload.files.1.fileId'));
        let list = get(uploadResult, "payload.files");
        let fliterList = list.splice(2);
        let listAtt =  fliterList.length > 0 ? fliterList.map(f => f.fileId) : this.formData.attachments.map(f => f.fileId);
        attachObj.attachments = listAtt
        this.setOffLineAttachments(listAtt)
      }

      const params = {isDraft: this.subStatus,cashManagementFormId: this.$route.query?.id || null,  ...this.formDataForSubmission,...attachObj};
      
      const result = await createSaveAsDraftSccf(params);
      this.setIsSubmitting(false);
      if (!result.success) {
        this.setError(result.payload);
        this.setShowModal(false);
        this.$scrollTop()
      } else {
        this.clearOfflineData();
        this.$router.push({ name: 'SCCF Main' });
      }
    },
    clearOfflineData() {
      if (this.isOfflineData) {
        const offlineId = get(this.$route.query, 'offlineId');
        this.$store.commit('offlineData/removeRequests', [ offlineId ]);
      }
    }
  }
}
</script>